import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="How Are Buses Made? From Frame to the Road | Painted Rhino"
            description="Painted Rhino is proud to manufacture some of the composite materials and fiberglass used to build buses and keep America moving."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="How Are Buses Made? From Frame to the Road"
            date="March 15, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     Whether or not we use it,{" "}
                     <a
                        href="https://www.apta.com/news-publications/public-transportation-facts/#:~:text=Quick%20Facts&amp;text=34%20million%20times%20each%20weekday%2C%20people%20board%20public%20transportation."
                        target="_blank"
                        rel="noreferrer"
                     >
                        public transportation
                     </a>{" "}
                     touches nearly every segment of American society. It’s an $80B industry that gives back more than five times what we invest in
                     it—every $1 invested in public transportation generates $5 in economic returns.
                  </p>

                  <p>
                     Public transportation not only gets us where we need to go, but it also reduces emissions and car accidents, bolsters our
                     economy, and even impacts real estate values.
                  </p>

                  <p>
                     In short, public transportation matters—and Painted Rhino is proud to manufacture the composite materials and fiberglass used to
                     build buses and keep America moving. Bus manufacturing is a fascinating process, and while every bus is unique, here’s a general
                     overview of how they’re made. 
                  </p>

                  <h2>Frame</h2>
                  <p>
                     Workers place the stainless steel bars into a press to build the frame, which uses 80-100 tons of pressure to bend the steel into
                     the desired shape. Workers then add the pieces to the base support frame or assembly jig.  Next, workers set and weld the roof,
                     add the side pieces, and place the internal structure on a rotating jig, which gives manufacturers easy access to any part of the
                     bus they need to weld.{" "}
                  </p>

                  <h2>Internal Structure</h2>
                  <p>
                     Meanwhile, other workers piece together the base flooring, using stainless steel materials to avoid rust and corrosion. Once the
                     floor takes shape, the frame goes back on the assembly jig, and workers begin welding the floor into place. 
                  </p>

                  <h2>Undercoating </h2>
                  <p>
                     With the frame and flooring in place, workers move the bus down the line, where they spray the underframe and lower section with
                     an anti-abrasion coating. This reduces noise and offers additional protection against road wear. 
                  </p>

                  <h2>Subfloor and Compressed Air</h2>
                  <p>
                     Next, workers apply high-strength glue to the floor structure, lay down a plywood subfloor, and screw it into place. Now, the bus
                     gets turned upside down again for workers to install four compressed air tanks under the roof. Three tanks are for the brake
                     system; the fourth powers the doors and other moving mechanisms on the bus. 
                  </p>

                  <h2>Waterproofing</h2>
                  <p>
                     With the subfloor and air system in place, workers now waterproof the subfloor with a vinyl covering designed to withstand heavy
                     use. 
                  </p>

                  <h2>Fiberglass Panels </h2>
                  <p>
                     The side and roof panels are next—this is where Painted Rhino comes into play. Using our
                     <a href="/light-resin-transfer-molding/">light resin transfer molding (or Light RTM) process</a>, we inject resin into a mold and
                     vacuum pressure it to conform to the material. The result is bus panels that resist rust, corrosion, and heavy use.
                  </p>

                  <h2>Engine, Air Conditioning, and Windows</h2>
                  <p>
                     Next, workers attach the bus’s low-emission diesel engine to the transmission and driveshaft in the engine compartment. After
                     that, workers install windows and the A/C system. 
                  </p>

                  <h2>Seats, Support Poles, and Driver’s Area</h2>
                  <p>
                     What’s a public bus without a place to sit? Painted Rhino uses the same Light RTM process to create molded fiberglass seats.
                     After these are installed, workers upholster the seats, add support poles for standing passengers, and install the driver’s seat,
                     steering wheel, wheelchair ramp, and controls. 
                  </p>

                  <h2>Testing and Quality Control Inspection  </h2>
                  <p>
                     The completed bus now undergoes a half-hour water infiltration test to ensure that it is watertight. During the entire process,
                     the bus is continually inspected for mechanical safety.
                  </p>

                  <iframe
                     className="mb-10"
                     width="100%"
                     height="315"
                     src="https://www.youtube.com/embed/y3fHuQSNg6A"
                     title="YouTube video player"
                     frameborder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowfullscreen
                  ></iframe>

                  <h2>About Painted Rhino</h2>
                  <p>
                     For more than 25 years, Painted Rhino has specialized in rotocasting, tooling, and mold making. We’re also one of the leading
                     experts in manufacturing composite materials, fiberglass, and FRP. We’re an American-owned, family-run business that serves
                     multiple industries, including <a href="/mass-transit-parts-manufacturer/">mass transit</a>,{" "}
                     <a href="/rv-component-manufacturer/">RV</a>, <a href="/fiberglass-product-manufacturer/">industrial</a>,{" "}
                     <a href="/fiberglass-medical-equipment-manufacturer/">medical</a>, <a href="/marine-fiberglass-manufacturer/">marine</a>, and
                     more. <a data-modal-open="modal-contact">Contact us</a> to discuss your project!
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Painted Rhino_ How are buses made_.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
